<template>
  <div>
    <b-row
      v-if="showSearch"
    >
      <b-col
        lg="6"
        class="my-1"
      >
        <b-form-group
          label="Filter by Role"
          label-for="role-select"
        >
          <v-select
            v-model="roleFilter"
            :options="roleOptions"
            :clearable="false"
            name="role-select"
          />
        </b-form-group>
      </b-col>

      <b-col
        lg="6"
        class="my-1"
      >
        <b-form-group
          label="Search"
          label-for="filter-input"
        >
          <b-input-group>
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
              id="filter-input"
              v-model="filter"
              name="filter-input"
              type="search"
              placeholder="Type to Search"
            />

            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="filter = ''"
              >
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row
      v-if="showSearch && activeUser.currentRole.name === 'admin'"
    >
      <b-col
        lg="6"
        class="my-1"
      >
        <b-form-group
          label="Filter by Office"
          label-for="office-select"
        >
          <v-select
            v-model="officeFilter"
            :options="officeOptions"
            :clearable="false"
            name="office-select"
          />
        </b-form-group>
      </b-col>
      <b-col
        v-if="false"
        lg="6"
        class="my-1"
      >
        <b-form-group
          label="Filter by Status"
          label-for="status-select"
        >
          <v-select
            v-model="statusFilter"
            :options="statusOptions"
            :clearable="false"
            name="status-select"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      v-b-modal.add-user-modal
      variant="primary"
    >
      <feather-icon
        icon="UserPlusIcon"
        class="mr-50"
      />
      <span class="align-middle">{{ $t('onboarding.add_user') }}</span>
    </b-button>
    <b-alert
      :show="true"
      variant="secondary"
      class="mt-1 mb-50"
    >
      <div class="alert-body text-center">
        <feather-icon
          icon="InfoIcon"
          class="mr-50"
        />
        <!-- eslint-disable vue/no-v-html -->
        <span>{{ $t('users.list_info') }}</span>
      </div>
    </b-alert>
    <b-alert
      :show="true"
      variant="secondary"
      class="mt-1 mb-50"
    >
      <div class="alert-body text-center">
        <feather-icon
          icon="InfoIcon"
          class="mr-50"
        />
        <!-- eslint-disable vue/no-v-html -->
        <span>{{ $t('users.deactivate_disclaimer') }}</span>
      </div>
    </b-alert>
    <b-table
      :fields="fields"
      :items="filteredUsers"
      :responsive="true"
      striped
      bordered
      :filter="filter"
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      class="mt-2"
      @filtered="onFiltered"
    >
      <template #cell(office)="data">
        <span v-if="data.item.office">{{ data.item.office.name }}</span>
        <span v-else>{{ officeName }}</span>
      </template>
      <template #cell(roles)="data">
        <b-badge
          v-for="item in data.item.roles"
          :key="item.id"
          class="badge"
          variant="primary"
        >
          {{ item.description }}
        </b-badge>
      </template>

      <template #cell(created_at)="data">
        {{ data.value | moment('LLL') }}
      </template>

      <template #cell(status)="data">
        <b-badge
          v-if="(data.item.state === 'deactivated' || data.item.state === 'unverified') && data.item.deactivated_at"
          class="badge"
          variant="danger"
        >
          Deactivated
        </b-badge>
        <b-badge
          v-else
          class="badge"
          variant="success"
        >
          Active
        </b-badge>
      </template>

      <template #cell(actions)="data">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          v-b-tooltip.hover.top="$t('buttons.edit')"
          variant="primary"
          class="btn-icon m-1"
          @click="editUser(data.item.id)"
        >
          <feather-icon icon="EditIcon" />
          {{ $t('buttons.edit') }}
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          v-b-tooltip.hover.top="$t('buttons.reset_password')"
          variant="primary"
          class="btn-icon m-1"
          @click="resetPassword(data.item.id)"
        >
          <feather-icon icon="LockIcon" />
          {{ $t('buttons.reset_password') }}
        </b-button>
        <b-button
          v-if="(data.item.state === 'deactivated' || data.item.state === 'unverified') && data.item.deactivated_at"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          v-b-tooltip.hover.top="$t('buttons.reactivate')"
          variant="primary"
          class="btn-icon m-1"
          @click="reactivate(data.item.id)"
        >
          <feather-icon icon="UserCheckIcon" />
          {{ $t('buttons.reactivate') }}
        </b-button>
        <b-button
          v-else
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          v-b-tooltip.hover.top="$t('buttons.deactivate')"
          variant="danger"
          class="btn-icon m-1"
          @click="deactivate(data.item.id)"
        >
          <feather-icon icon="UserXIcon" />
          {{ $t('buttons.deactivate') }}
        </b-button>
        <!-- <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          class="m=1"
          :to="{ name: 'offices-view', params: { officeId: data.item.id } }"
        >
          <feather-icon
            icon="EyeIcon"
            class="mr-50"
          />
          <span class="align-middle">{{ $t('buttons.view') }}</span>
        </b-button> -->
      </template>

      <!-- Optional default data cell scoped slot -->
      <template #cell()="data">
        {{ data.value }}
      </template>
    </b-table>
    <p
      v-if="filteredUsers.length == 0"
      class="text-center"
    >
      {{ $t('no_data') }}
    </p>
    <b-col
      cols="12"
    >
      <b-pagination
        v-show="filteredUsers.length > 0"
        v-model="currentPage"
        :per-page="perPage"
        :total-rows="totalRows"
        align="center"
        size="sm"
        class="my-0"
      />
    </b-col>
    <add-user-modal
      @success="onSuccess"
      @error="onError"
    />
    <edit-user-modal
      @success="onSuccess"
      @error="onError"
    />
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BTable,
  BPagination,
  BBadge,
  VBModal,
  VBTooltip,
  BAlert,
} from 'bootstrap-vue'
import AddUserModal from '@/views/components/modal/AddUserModal.vue'
import EditUserModal from '@/views/components/modal/EditUserModal.vue'
import Ripple from 'vue-ripple-directive'
import { mapGetters, mapActions } from 'vuex'
import vSelect from 'vue-select'

export default {
  components: {
    BCol,
    BRow,
    BButton,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BTable,
    BPagination,
    // eslint-disable-next-line vue/no-unused-components
    BBadge,
    vSelect,
    AddUserModal,
    EditUserModal,
    BAlert,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
  },
  filters: {
    capitalize(value) {
      if (!value) return ''
      // eslint-disable-next-line no-param-reassign
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    },
  },
  props: {
    showSearch: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      fields: [
        { key: 'first_name', label: 'First Name', sortable: true },
        { key: 'last_name', label: 'Last Name', sortable: true },
        { key: 'email', label: 'Email', sortable: true },
        { key: 'office', label: 'Office', sortable: true },
        { key: 'roles', label: 'Roles', sortable: true },
        { key: 'status', label: 'Status', sortable: true },
        { key: 'created_at', label: 'Created Date', sortable: true },
        { key: 'actions', label: 'Actions' },
      ],
      perPage: 10,
      currentPage: 1,
      sortBy: 'created_at',
      sortDesc: true,
      filter: null,
      totalRows: 1,
      officeFilter: { label: 'All', value: 'all' },
      roleFilter: { label: 'All', value: 'all' },
      statusFilter: { label: 'All', value: 'all' },
    }
  },
  computed: {
    /* eslint-disable no-plusplus */
    ...mapGetters('auth', ['activeUser']),
    ...mapGetters('role', ['roles']),
    ...mapGetters('office', ['offices']),
    ...mapGetters('user', ['users']),
    // users() {
    //   let list = []
    //   if (this.activeUser && this.activeUser.office && this.activeUser.scope && !this.activeUser.scope.includes('admin') && this.activeUser.office.users) {
    //     console.log(this.activeUser.office.users)
    //     list = this.activeUser.office.users.filter(u => !u.scope.includes('patient'))
    //   } else {
    //     list = this.$store.getters['user/users']
    //   }
    //   return list
    // },
    officeName() {
      let name = null
      if (this.activeUser && this.activeUser.office) {
        name = this.activeUser.office.name
      }
      return name
    },
    roleOptions() {
      const roleOptions = [{
        label: 'All',
        value: 'all',
      }]
      for (let i = 0; i < this.roles.length; i++) {
        const role = {}
        role.label = this.roles[i].description
        role.value = this.roles[i].id
        roleOptions.push(role)
      }
      return roleOptions
    },
    officeOptions() {
      const officeOptions = [{
        label: 'All',
        value: 'all',
      }]
      for (let i = 0; i < this.offices.length; i++) {
        const office = {}
        office.label = this.offices[i].name
        office.value = this.offices[i].id
        officeOptions.push(office)
      }
      return officeOptions
    },
    statusOptions() {
      const statusOptions = [{
        label: 'All',
        value: 'all',
      }]
      let status = {}
      status.label = 'Active'
      status.value = 'activated'
      statusOptions.push(status)
      status = {}
      status.label = 'Deactivated'
      status.value = 'deactivated'
      statusOptions.push(status)
      return statusOptions
    },
    filteredUsers() {
      try {
        if (this.officeFilter) {
          if (this.officeFilter.value === 'all' && this.roleFilter.value === 'all' && this.statusFilter.value === 'all') {
            return this.users
          }
          if (this.officeFilter.value === 'all' && this.roleFilter.value !== 'all') {
            return this.users.filter(u => u.roles.filter(r => r.id === this.roleFilter.value).length > 0)
          }
          if (this.officeFilter.value !== 'all' && this.roleFilter.value === 'all') {
            return this.users.filter(u => u.office_id === this.officeFilter.value)
          }
          return this.users.filter(u => u.roles.filter(r => r.id === this.roleFilter.value).length > 0 && u.office_id === this.officeFilter.value)
        }
        if (this.roleFilter.value !== 'all') {
          this.users.filter(u => u.roles.filter(r => r.id === this.roleFilter.value).length > 0)
        }
        return this.users
      } catch (e) {
        console.warn('Could not apply filter')
        return this.users
      }
    },
  },
  async created() {
    try {
      if (this.activeUser) {
        await this.getUsers()
        await this.getOffices()
      }
      await this.getRoles()
    } catch (e) {
      console.debug(`Error in created() of UsersTable.vue: ${e.message}`)
    }
  },
  mounted() {
    this.totalRows = this.filteredUsers.length
  },
  methods: {
    ...mapActions('user', ['getUsers', 'getUser', 'deactivateUser', 'reactivateUser']),
    ...mapActions('role', ['getRoles']),
    ...mapActions('office', ['getOffices']),
    ...mapActions('auth', ['recoverPassword']),
    findRole(id) {
      return this.roles.find(r => r.id === id)
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    onSuccess(event) {
      this.$emit('success', event)
    },
    onError(event) {
      this.$emit('error', event)
    },
    async editUser(id) {
      try {
        await this.getUser(id)
        // show the modal manually
        this.$nextTick(() => {
          this.$bvModal.show('edit-user-modal')
        })
      } catch {
        console.debug(`Cannot edit user with id: ${id}`)
      }
    },
    async resetPassword(id) {
      try {
        const response = await this.getUser(id)
        const user = response.data
        this.$swal({
          title: 'Reset Password',
          text: 'Please confirm you would like to reset password for this user. An email with instructions will be sent to this user.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, reset password',
          customClass: {
            confirmButton: 'btn btn-warning',
            cancelButton: 'btn btn-outline-primary ml-1',
          },
          buttonsStyling: false,
        }).then(async result => {
          if (result.value) {
            // eslint-disable-next-line no-useless-catch
            try {
              await this.recoverPassword({ email: user.email })
              this.$emit('success', { message: 'Password reset instructions sent' })
            } catch (e) {
              throw e
            }
          }
        }).catch(e => {
          throw e
        })
      } catch {
        console.debug(`Cannot fetch user with id: ${id}`)
        this.$emit('error', { message: 'Could not reset password' })
      }
    },
    async deactivate(id) {
      try {
        this.$swal({
          title: 'Deactivate User',
          text: 'Please confirm you would like to deactivate this user. Deactivating a user will remove the license assigned to this user and will be reflected in your next invoice.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, deactivate user',
          customClass: {
            confirmButton: 'btn btn-warning',
            cancelButton: 'btn btn-outline-primary ml-1',
          },
          buttonsStyling: false,
        }).then(async result => {
          if (result.value) {
            // eslint-disable-next-line no-useless-catch
            try {
              await this.deactivateUser(id)
              this.$emit('success', { message: 'User successfully deactivated' })
            } catch (e) {
              throw e
            }
          }
        }).catch(e => {
          throw e
        })
      } catch {
        console.debug(`Cannot deactivate user with id: ${id}`)
        this.$emit('error', { message: 'Could not deactivate user' })
      }
    },
    async reactivate(id) {
      try {
        this.$swal({
          title: 'Reactivate User',
          text: 'Please confirm you would like to reactivate this user. By reactivating a user, you will be charged a prorated amount on your next invoice. Reactivating users may result in the charges on your invoice varying month to month.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, reactivate user',
          customClass: {
            confirmButton: 'btn btn-warning',
            cancelButton: 'btn btn-outline-primary ml-1',
          },
          buttonsStyling: false,
        }).then(async result => {
          if (result.value) {
            // eslint-disable-next-line no-useless-catch
            try {
              await this.reactivateUser(id)
              this.$emit('success', { message: 'User successfully reactivated' })
            } catch (e) {
              throw e
            }
          }
        }).catch(e => {
          throw e
        })
      } catch {
        console.debug(`Cannot reactivate user with id: ${id}`)
        this.$emit('error', { message: 'Could not reactivate user' })
      }
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  .badge {
    margin: 0.2em;
  }
</style>
