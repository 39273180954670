var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.selectedUser)?_c('b-modal',{attrs:{"id":"edit-user-modal","title":_vm.$t('page_titles.edit_user'),"ok-title":"Submit","cancel-variant":"outline-secondary"},on:{"ok":_vm.handleOk,"show":_vm.resetModal,"hidden":_vm.resetModal}},[_c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-observer',{ref:"editUserForm"},[_c('b-overlay',{attrs:{"show":_vm.loading,"variant":_vm.overlayVariant,"opacity":_vm.overlayOpacity,"rounded":"sm"}},[_c('b-form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleSubmit($event)}}},[_c('b-form-group',{attrs:{"label":"First Name","label-for":"first_name"}},[_c('validation-provider',{attrs:{"name":"First Name","vid":"first_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"id":"first_name","state":errors.length > 0 ? false:null,"name":"first_name","placeholder":"Enter first name","data-vv-validate-on":"blur"},model:{value:(_vm.user.first_name),callback:function ($$v) {_vm.$set(_vm.user, "first_name", $$v)},expression:"user.first_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1267297635)})],1),_c('b-form-group',{attrs:{"label":"Last Name","label-for":"last_name"}},[_c('validation-provider',{attrs:{"name":"Last Name","vid":"last_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"id":"last_name","state":errors.length > 0 ? false:null,"name":"last_name","placeholder":"Enter last name","data-vv-validate-on":"blur"},model:{value:(_vm.user.last_name),callback:function ($$v) {_vm.$set(_vm.user, "last_name", $$v)},expression:"user.last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,792394275)})],1),_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"Email","vid":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"id":"email","state":errors.length > 0 ? false:null,"name":"email","placeholder":"Enter email address","data-vv-validate-on":"blur"},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2408298442)})],1),_c('b-form-group',{attrs:{"label":"Office","label-for":"office"}},[(_vm.activeUser && _vm.activeUser.currentRole.name !== 'admin')?_c('b-form-input',{attrs:{"id":"office","value":_vm.activeUser.office.name,"name":"office","disabled":""}}):_c('div',[_c('v-select',{attrs:{"id":"office-select","reduce":function (office) { return office.id; },"name":"office-select","label":"name","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.offices},model:{value:(_vm.user.office_id),callback:function ($$v) {_vm.$set(_vm.user, "office_id", $$v)},expression:"user.office_id"}})],1)],1),_c('b-form-group',{attrs:{"label":"Select Role(s)","label-for":"role-select"}},[_c('validation-provider',{attrs:{"name":"Roles","vid":"role-select","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:errors.length > 0 ? 'is-invalid':null,attrs:{"id":"role-select","name":"role-select","label":"description","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"state":errors.length > 0 ? false:null,"options":_vm.roles,"multiple":""},model:{value:(_vm.user.roles),callback:function ($$v) {_vm.$set(_vm.user, "roles", $$v)},expression:"user.roles"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,983644854)})],1),_c('b-alert',{staticClass:"mt-1 mb-50",attrs:{"show":true,"variant":"info"}},[_c('div',{staticClass:"alert-body text-center"},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"InfoIcon"}}),_c('span',[_vm._v("Roles represent one's position/duty within an office. Since some office members may hold multiple positions, you may assign them multiple roles if needed. For example, if someone performs the duties of a Receptionist and a Treatment Coordinator, you can assign both of those roles to a user.")])],1)])],1),_c('b-alert',{directives:[{name:"height-fade",rawName:"v-height-fade.appear",modifiers:{"appear":true}}],attrs:{"variant":"danger","show":_vm.serverError !== null}},[_c('div',{staticClass:"alert-body"},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"InfoIcon"}}),_c('span',{domProps:{"innerHTML":_vm._s(_vm.serverError)}})],1)])],1)],1)],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }