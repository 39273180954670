<template>
  <b-modal
    id="add-user-modal"
    :title="$t('onboarding.add_user')"
    ok-title="Submit"
    cancel-variant="outline-secondary"
    no-close-on-backdrop
    @ok="handleOk"
    @show="resetModal"
    @hidden="resetModal"
  >
    <b-container fluid>
      <b-row>
        <b-col cols="12">
          <validation-observer
            ref="addUserForm"
          >
            <b-overlay
              :show="loading"
              :variant="overlayVariant"
              :opacity="overlayOpacity"
              rounded="sm"
            >
              <b-alert
                show
                variant="info"
              >
                <div class="alert-body text-center">
                  <feather-icon
                    class="mr-25"
                    icon="InfoIcon"
                  />
                  <span class="ml-25">An email containing a temporary password will automatically be sent to the user after clicking Submit</span>
                </div>
              </b-alert>
              <b-form @submit.stop.prevent="handleSubmit">
                <b-form-group
                  label="First Name"
                  label-for="first_name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="First Name"
                    vid="first_name"
                    rules="required"
                  >
                    <b-form-input
                      id="first_name"
                      v-model="user.first_name"
                      :class="errors.length > 0 ? 'is-invalid':null"
                      :state="errors.length > 0 ? false:null"
                      name="first_name"
                      placeholder="Enter first name"
                      data-vv-validate-on="blur"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label="Last Name"
                  label-for="last_name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Last Name"
                    vid="last_name"
                    rules="required"
                  >
                    <b-form-input
                      id="last_name"
                      v-model="user.last_name"
                      :class="errors.length > 0 ? 'is-invalid':null"
                      :state="errors.length > 0 ? false:null"
                      name="last_name"
                      placeholder="Enter last name"
                      data-vv-validate-on="blur"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label="Email"
                  label-for="email"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    vid="email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="email"
                      v-model="user.email"
                      :class="errors.length > 0 ? 'is-invalid':null"
                      :state="errors.length > 0 ? false:null"
                      name="email"
                      placeholder="Enter email address"
                      data-vv-validate-on="blur"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label="Office"
                  label-for="office"
                >
                  <b-form-input
                    v-if="activeUser && activeUser.currentRole.name !== 'admin'"
                    id="office"
                    :value="activeUser.office.name"
                    name="office"
                    disabled
                  />
                  <div v-else>
                    <v-select
                      id="office-select"
                      v-model="user.office_id"
                      :reduce="office => office.id"
                      name="office-select"
                      label="name"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="offices"
                    />
                  </div>
                </b-form-group>
                <b-form-group
                  label="Select Role(s)"
                  label-for="role-select"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Roles"
                    vid="role-select"
                    rules="required"
                  >
                    <v-select
                      id="role-select"
                      v-model="user.roles"
                      name="role-select"
                      label="description"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :class="errors.length > 0 ? 'is-invalid':null"
                      :state="errors.length > 0 ? false:null"
                      :options="roles"
                      multiple
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-alert
                  :show="true"
                  variant="info"
                  class="mt-1 mb-50"
                >
                  <div class="alert-body text-center">
                    <feather-icon
                      icon="InfoIcon"
                      class="mr-50"
                    />
                    <!-- eslint-disable vue/no-v-html -->
                    <span>Roles represent one's position/duty within an office. Since some office members may hold multiple positions, you may assign them multiple roles if needed. For example, if someone performs the duties of a Receptionist and a Treatment Coordinator, you can assign both of those roles to a user.</span>
                  </div>
                </b-alert>
                <b-alert
                  :show="true"
                  variant="warning"
                  class="mb-50"
                >
                  <div class="alert-body text-center">
                    <feather-icon
                      icon="InfoIcon"
                      class="mr-50"
                    />
                    <!-- eslint-disable vue/no-v-html -->
                    <span>By clicking submit, you will be charged a prorated amount on your next invoice. Adding users may result in the charges on your invoice varying month to month.</span>
                  </div>
                </b-alert>
              </b-form>

              <!-- server alert -->
              <b-alert
                v-height-fade.appear
                variant="danger"
                :show="serverError !== null"
              >
                <div
                  class="alert-body"
                >
                  <feather-icon
                    icon="InfoIcon"
                    class="mr-50"
                  />
                  <span v-html="serverError" />
                </div>
              </b-alert>

            </b-overlay>
          </validation-observer>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  BAlert, BOverlay, BModal, BForm, BFormInput, BFormGroup, BContainer, BRow, BCol,
} from 'bootstrap-vue'
import {
  required,
  email,
} from '@validations'
import {
  heightFade,
} from '@core/directives/animations'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import vSelect from 'vue-select'

export default {
  components: {
    BAlert,
    BOverlay,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BContainer,
    BRow,
    BCol,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'height-fade': heightFade,
  },
  data() {
    return {
      user: {},
      loading: false,
      overlayOpacity: 0.75,
      overlayVariant: 'white',
      required,
      email,
      serverError: null,
    }
  },
  computed: {
    ...mapGetters('auth', ['activeUser']),
    ...mapGetters('role', ['roles']),
    ...mapGetters('office', ['offices']),
  },
  async created() {
    if (this.activeUser && this.activeUser.role && this.activeUser.currentRole.name === 'admin') {
      await this.getOffices()
    }
    await this.getRoles()
  },
  methods: {
    ...mapActions('user', ['createUser']),
    ...mapActions('auth', ['getUserProfile']),
    ...mapActions('office', ['getOffices']),
    ...mapActions('role', ['getRoles']),

    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit() {
      this.$refs.addUserForm.validate().then(async success => {
        if (success) {
          try {
            this.loading = true
            this.serverError = null
            if (!this.user.office_id) {
              const adminRole = this.user.roles.find(r => r.name === 'admin')
              if (!adminRole) {
                this.user.office_id = this.activeUser.office_id
              } else {
                throw new Error('No office selected')
              }
            }
            // Submit the form
            await this.createUser(this.user)
            await this.getUserProfile()
            // Hide the modal manually
            this.$nextTick(() => {
              this.$bvModal.hide('add-user-modal')
            })
            this.$emit('success', { message: 'User added successfully' })
          } catch (e) {
            console.debug(`Submit error in AddUserModal.vue: ${e.message}`)
            this.serverError = this.$t('errors.generic')
            this.$emit('error', { message: 'Could not add user' })
          } finally {
            this.loading = false
          }
        }
      })
    },
    resetModal() {
      this.user = {}
    },
  },
}
</script>

<style lang="scss">
  .is-invalid {
    .vs__dropdown-toggle {
      border-color: #ea5455 !important;
    }
  }
</style>
